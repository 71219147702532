import { COLORS } from 'helpers/constants';

export const ConnectionOneDirectionIcon = ({
  firstColor = COLORS.PRIMARY.GRAY,
  secondColor = COLORS.PRIMARY.GRAY,
}: {
  firstColor?: string;
  secondColor?: string;
}) => {
  return (
    <svg width="19" height="10" viewBox="0 0 19 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id={firstColor}>
          <stop offset="0%" stopColor={firstColor} />
          <stop offset="100%" stopColor={secondColor} />
        </linearGradient>
      </defs>
      <path
        d="M12.5715 5.86591C8.66297 5.86554 4.80196 5.86659 0.940946 5.86344C0.245289 5.86287 -0.0335476 5.56538 0.00318751 4.89674C0.0278186 4.44842 0.256306 4.20441 0.648986 4.14051C0.804023 4.11529 0.964854 4.13061 1.12305 4.1306C5.85437 4.13051 10.5857 4.13081 15.317 4.13105C15.4889 4.13106 15.6609 4.13105 15.8655 4.13105C15.8431 3.87639 15.6811 3.77513 15.5686 3.64989C14.9485 2.95928 14.3111 2.28676 13.6997 1.58712C13.2151 1.03251 13.3339 0.319803 13.9244 0.0646639C14.265 -0.0824896 14.5559 0.0336694 14.7928 0.29018C16.152 1.76191 17.501 3.24478 18.8588 4.71806C19.045 4.92014 19.0487 5.07333 18.8598 5.27815C17.5124 6.73835 16.1765 8.21119 14.8261 9.66798C14.4291 10.0962 13.9913 10.1012 13.6463 9.73491C13.3018 9.36928 13.3074 8.84446 13.6968 8.40182C14.2666 7.75422 14.8574 7.12869 15.4374 6.49177C15.5682 6.34814 15.6914 6.19632 15.8181 6.04826C15.8089 5.98748 15.7997 5.92669 15.7905 5.8659C14.7333 5.8659 13.6761 5.8659 12.5715 5.86591Z"
        fill={`url(#${firstColor})`}
      />
    </svg>
  );
};
