import { ControlsComponent } from './ControlsComponent';
import { LayoutComponent } from './LayoutComponent';

export const Settings = () => {
  return (
    <>
      <ControlsComponent />
      <LayoutComponent />
    </>
  );
};
