export const ArrowD = `M19.431 15.749
  C19.3858 15.5906 18.6851 14.7456 16.217 11.8568
  C14.4811 9.82883 13.003 8.13356 12.9261 8.08603
  C12.6097 7.89063 12.2255 8.0385 12.0808 8.42403
  C11.9 8.90462 11.7327 8.67225 15.0191 12.5222
  L17.989 15.992
  L15.0191 19.467
  C11.7327 23.3117 11.9 23.0793 12.0808 23.5599
  C12.1712 23.7976 12.2616 23.8874 12.5012 23.9666
  C12.8312 24.0775 12.7679 24.1409 16.1492 20.2011
  C17.8986 18.1626 19.3496 16.4462 19.3767 16.3881
  C19.4717 16.1874 19.4943 15.955 19.431 15.749Z`;

export const edgePropertyD = `M17.4617 11.0255
  C16.6997 11.1513 16.0384 11.7767 15.8587 12.5314
  C15.7545 12.9735 15.812 13.4192 16.0384 13.9475
  C16.0564 13.9907 15.068 15.0653 13.961 16.2046
  C13.9251 16.2442 13.8676 16.2334 13.6483 16.1615
  C13.0877 15.9746 12.6025 16.0034 12.0957 16.2585
  C10.8809 16.8587 10.6257 18.4689 11.5853 19.4285
  C11.9735 19.8167 12.4371 20 13.005 19.9964
  C13.5729 19.9928 14.0365 19.7879 14.4211 19.371
  C14.9674 18.7816 15.1076 17.9406 14.7841 17.1966
  L14.7014 17.0133 L15.7797 15.8812 L16.8579 14.749
  L17.0807 14.8425
  C17.2784 14.9287 17.3431 14.9359 17.7852 14.9359
  C18.2272 14.9359 18.2919 14.9287 18.4932 14.8425
  L18.716 14.749 L19.7979 15.8812
  C20.3909 16.5065 20.8689 17.0349 20.8617 17.0528
  C20.6784 17.459 20.6497 17.5776 20.6317 17.919
  C20.6065 18.5336 20.7755 18.9865 21.1996 19.4142
  C21.5805 19.7987 22.055 20 22.6013 20
  C23.144 20 23.6112 19.8023 24.003 19.4106
  C24.578 18.8355 24.7398 17.9693 24.4127 17.2218
  C24.33 17.0385 24.33 17.0277 24.3911 16.9522
  C24.4235 16.9091 24.9087 16.3951 25.4694 15.8093
  L26.4865 14.749 L26.7093 14.8425
  C26.9106 14.9251 26.9789 14.9359 27.3814 14.9359
  C27.9026 14.9359 28.1254 14.8784 28.4633 14.6592
  C29.0383 14.289 29.3941 13.6384 29.3941 12.9591
  C29.3941 12.7147 29.2755 12.2619 29.1533 12.0319
  C29.0203 11.7875 28.6358 11.4029 28.3842 11.2591
  C28.1362 11.119 27.6941 11.0004 27.421 11.0004
  C26.6698 11.0039 25.9618 11.4424 25.6167 12.1217
  C25.3723 12.5997 25.3759 13.3617 25.6275 13.8505
  L25.6958 13.9871 L25.0201 14.6951
  C24.6499 15.0833 24.1575 15.5936 23.9311 15.8272
  L23.5178 16.2478 L23.2482 16.1507
  C23.0182 16.068 22.9211 16.0537 22.6013 16.0537
  C22.2814 16.0537 22.1808 16.068 21.9471 16.1543
  L21.6668 16.2513 L21.2427 15.8093
  C20.0279 14.5441 19.5283 14.0086 19.5355 13.9691
  C19.5391 13.9475 19.5858 13.8253 19.6397 13.6959
  C19.7152 13.5162 19.7404 13.3832 19.7547 13.1209
  C19.7763 12.6932 19.726 12.4416 19.5427 12.0822
  C19.1689 11.3346 18.2883 10.8853 17.4617 11.0255Z`;

export const TypeSettingD = `M6.81013 0.738855
  C6.73418 0.794551 6.67848 1.0224 6.53165 1.88316
  L6.34937 2.96164 L5.87342 3.15911
  C5.61013 3.2705 5.33671 3.38189 5.26582 3.41227
  C5.1443 3.46291 5.06329 3.41734 4.27848 2.85025
  C3.63544 2.38949 3.38228 2.23759 3.26582 2.23759
  C3.13418 2.23759 2.99747 2.35404 2.33924 3.01227
  C1.75696 3.59455 1.56962 3.81227 1.56962 3.90847
  C1.56962 3.98949 1.79241 4.34898 2.15696 4.8705
  C2.48101 5.33126 2.75443 5.73632 2.76962 5.7667
  C2.78481 5.80215 2.7443 5.93885 2.67848 6.0705
  C2.61772 6.19708 2.50633 6.46544 2.43544 6.66291
  L2.3038 7.01734 L1.22025 7.19961
  C0.35443 7.34645 0.126582 7.40215 0.0708861 7.4781
  C0.0151899 7.55404 0 7.81734 0 8.66797
  C0 9.66544 0.0101266 9.77177 0.0911392 9.84772
  C0.151899 9.90341 0.546835 9.99961 1.24051 10.1211
  L2.29873 10.3135 L2.41013 10.6173
  C2.46582 10.7844 2.57722 11.0477 2.6481 11.2047
  C2.72405 11.3616 2.78481 11.5034 2.78481 11.5186
  C2.78481 11.5389 2.51139 11.9338 2.17722 12.3996
  C1.72152 13.0376 1.56962 13.2857 1.56962 13.4021
  C1.56962 13.5338 1.68608 13.6705 2.34937 14.3338
  C3.0481 15.0325 3.1443 15.1085 3.27089 15.0882
  C3.3519 15.0781 3.80253 14.7946 4.27342 14.4654
  C5.06329 13.9085 5.13418 13.868 5.26076 13.9237
  C5.33165 13.954 5.61013 14.0654 5.87342 14.1768
  L6.34937 14.3743 L6.53165 15.4528
  C6.67848 16.3135 6.73418 16.5414 6.81013 16.5971
  C6.88608 16.6528 7.14937 16.668 8 16.668
  C9.01266 16.668 9.1038 16.6629 9.18481 16.5718
  C9.24557 16.5059 9.33165 16.1363 9.45823 15.4072
  L9.64557 14.3389 L9.89873 14.2528
  C10.0405 14.2072 10.3139 14.1009 10.5063 14.0097
  L10.8658 13.8477 L11.7468 14.4705
  C12.3089 14.8705 12.6785 15.0983 12.7595 15.0983
  C12.8557 15.0983 13.0734 14.9161 13.6608 14.3237
  C14.3089 13.6756 14.4304 13.5287 14.4304 13.4021
  C14.4304 13.2908 14.2684 13.0275 13.7975 12.3743
  L13.1646 11.4983 L13.3114 11.2097
  C13.3924 11.0528 13.5038 10.7844 13.5646 10.6173
  L13.6759 10.3135 L14.7443 10.1262
  C15.4684 9.99961 15.838 9.91354 15.9038 9.85278
  C15.9949 9.77177 16 9.68063 16 8.65784
  C16 7.65531 15.9899 7.54392 15.9089 7.46291
  C15.838 7.39202 15.5544 7.3262 14.7797 7.19961
  C14.2127 7.10848 13.7215 7.00721 13.6911 6.97683
  C13.6608 6.94645 13.5949 6.80468 13.5443 6.66797
  C13.4937 6.5262 13.3873 6.28316 13.3063 6.1262
  L13.1646 5.83253 L13.7975 4.93632
  C14.2025 4.35911 14.4304 3.98949 14.4304 3.90847
  C14.4304 3.81227 14.2481 3.59455 13.6557 3.00721
  C13.0076 2.35911 12.8608 2.23759 12.7342 2.23759
  C12.6228 2.23759 12.3747 2.38949 11.7722 2.82493
  C11.3266 3.14898 10.9316 3.4224 10.8962 3.43759
  C10.8608 3.45278 10.6481 3.38696 10.4152 3.28569
  C10.1873 3.18949 9.91899 3.08316 9.82278 3.05278
  L9.64051 2.99708 L9.45823 1.92873
  C9.33165 1.20468 9.24051 0.824931 9.18481 0.764171
  C9.1038 0.678095 9.00759 0.667969 8 0.667969
  C7.14937 0.667969 6.88608 0.683159 6.81013 0.738855
  Z M8.55696 6.29329
  C8.9519 6.38442 9.41266 6.64265 9.71646 6.95151
  C10.6684 7.91354 10.6684 9.4224 9.72152 10.3794
  C8.76962 11.3363 7.24557 11.3414 6.28861 10.3794
  C4.96709 9.05784 5.56962 6.80974 7.37722 6.31354
  C7.67595 6.23253 8.2481 6.2224 8.55696 6.29329Z`;

export const EyeD = `M1.30882 2.09974
  C2.20119 1.18266 3.1801 0.676137 4.37534 0.51085
  C5.44078 0.361558 6.73878 0.660142 7.71769 1.2733
  C8.44781 1.73718 9.31855 2.70757 9.76203 3.57133
  L10 4.02987L9.76744 4.47775
  C9.45917 5.06958 8.99946 5.67208 8.48026 6.16261
  C7.98269 6.62648 7.58789 6.89841 7.02542 7.15434
  C5.79232 7.71418 4.20227 7.71418 2.96917 7.15434
  C1.84424 6.64248 0.773391 5.58144 0.178475 4.38711
  L0 4.02987L0.178475 3.66731
  C0.465116 3.0968 0.822066 2.60094 1.30882 2.09974Z
  M7.22012 4.35511
  C7.40941 3.19277 6.54949 2.03576 5.34884 1.84381
  C5.17036 1.81715 4.98648 1.79583 4.94321 1.79583
  C3.8291 1.88114 2.94754 2.65425 2.77447 3.70463
  C2.58518 4.8723 3.45051 6.02398 4.66198 6.21593
  C5.85722 6.40788 7.02001 5.56011 7.22012 4.35511Z
  M3.91568 4.81825
  C3.38026 4.09312 3.69935 3.0854 4.54846 2.77082
  C4.67826 2.72284 4.88377 2.70151 5.08929 2.7175
  C6.24667 2.78682 6.76587 4.1571 5.94381 4.96221
  C5.36512 5.53272 4.38621 5.46341 3.91568 4.81825Z`;

export const EyeClosedD = `M1.34461 1.08333
  C0.817155 0.5525 0.756717 0.384583 1.01495 0.135417
  C1.10286 0.0433331 1.20725 0 1.32813 0
  C1.49296 0 1.82262 0.30875 5.31154 3.75375
  C8.80596 7.19333 9.11914 7.51833 9.11914 7.68083
  C9.11914 7.91917 8.91035 8.125 8.67409 8.125
  C8.52575 8.125 8.41586 8.04375 7.95983 7.59958
  L7.42138 7.07958L7.10271 7.22042
  C6.3335 7.55625 5.37198 7.72958 4.64123 7.66458
  C2.92699 7.51292 1.50395 6.63 0.438044 5.05917
  C0.00948318 4.42542 -0.023483 4.33333 0.00948319 3.95417
  C0.0479437 3.58583 0.630346 2.73542 1.30066 2.06375
  L1.81163 1.54917L1.34461 1.08333ZM5.39396 5.35167
  C4.36651 5.655 3.39401 4.72333 3.67972 3.705
  L3.75114 3.45583L3.41599 3.12542
  L3.08083 2.795L2.93798 3.09833
  C2.52041 3.9975 2.71271 5.01583 3.43247 5.68208
  C4.13026 6.3375 5.09726 6.48917 5.97636 6.09375
  L6.28405 5.95292L5.94889 5.6225L5.60824 5.28667
  L5.39396 5.35167ZM3.96543 0.573667
  C4.21268 0.524917 4.49839 0.476167 4.5863 0.465333
  C4.6797 0.4545 4.9764 0.4545 5.25661 0.465333
  C6.97634 0.552 8.45433 1.44575 9.56968 3.07617
  C9.91033 3.5745 9.99824 3.78033 9.99824 4.07825
  C9.99824 4.39242 9.73451 4.82575 8.91036 5.83867
  C8.84992 5.9145 8.74003 5.82783 8.04225 5.14533
  C7.30601 4.4195 7.24557 4.34908 7.27304 4.19742
  C7.32249 3.93742 7.2126 3.412 7.03129 3.0545
  C6.83349 2.65367 6.37196 2.2095 5.96538 2.02533
  C5.62473 1.87367 5.10277 1.77617 4.86101 1.8195
  C4.71267 1.84658 4.64673 1.79783 4.10279 1.25617
  L3.50391 0.66575L3.96543 0.573667Z`;
