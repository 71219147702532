export const GET_PROJECTS_TASKS = '/jira/issue/projects/:id';
export const DELETE_TASK_BY_ID = '/jira/issue/:id';
export const GET_JIRA_ISSUE = '/jira/issue/:nodeId';
export const GET_JIRA_ISSUE_TYPES = '/jira/issue/types/:projectID';
export const GET_JIRA_PROJECT = '/jira/projects';
export const GET_JIRA_USERS = '/jira/users/:projectID';
export const GET_JIRA_ISSUE_STATUSES = '/jira/issue/statuses/:nodeId';
export const CREATE_JIRA_PROJECT_URL = '/jira/projects/:project_id';
export const JIRA_REDIRECT_URL = '/jira/redirect/:id';
export const JIRA_CREATE_ISSUE_URL = '/jira/issue/:nodeId';
