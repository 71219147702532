import { Badge } from 'antd';
import { ReactComponent as Araks } from '../../icons/araks-tiny.svg';
import { ProjectWrapper, StyledTitle, StyledAvatar } from './styles';
import { Icon } from '../../icon';

export const renderProperty = (
  id: string,
  title: string,
  color: string,
  node_id: string,
  project_id: string,
  icon: string,
  privacy: string,
  user_id: string
) => {
  return {
    key: `propertyType${id}`,
    id,
    mode: 'propertyType',
    project_id: project_id,
    value: id,
    node_id,
    user_id,
    privacy,
    label: (
      <ProjectWrapper>
        <Badge>
          <StyledAvatar
            color={color}
            shape="square"
            size="small"
            icon={
              (
                <Icon
                  color="#414141"
                  icon={icon}
                  size={14}
                  style={{ display: 'flex', height: '100%', margin: '0 auto' }}
                />
              ) || <Araks />
            }
          />
        </Badge>
        <StyledTitle>{title}</StyledTitle>
      </ProjectWrapper>
    ),
  };
};
