export const antTheme = {
  components: {
    Input: {
      borderRadius: 4,
      colorBorder: '#808080',
      colorBgContainer: '#ffffff',
      colorPrimaryHover: '#808080',
      colorPrimaryActive: '#808080',
      colorPrimary: '#808080',
      colorError: '#F3BFC3',
      controlOutline: '',
      controlOutlineWidth: 0,
      colorTextDisabled: 'rgba(35, 47, 106, 0.5)',
      colorTextDescription: 'rgba(0, 0, 0, 0.45)',
      colorText: '#414141',
      colorTextPlaceholder: 'rgba(35, 47, 106, 0.5)',
      // controlHeight: 43,
      colorFillAlter: '#ffffff',
      colorTextQuaternary: 'rgba(0, 0, 0, 0.25)',
      colorTextTertiary: 'rgba(0, 0, 0, 0.45)',
      colorBgContainerDisabled: '#ffffff',
    },
    DatePicker: {
      borderRadius: 4,
      colorBorder: '#808080',
      colorTextPlaceholder: 'rgba(35, 47, 106, 0.5)',
      colorError: '#F3BFC3',
      colorPrimary: '#1677ff',
      colorPrimaryBorder: '#808080',
      colorText: '#414141',
      colorTextDisabled: 'rgba(35, 47, 106, 0.5)',
      controlHeight: 40,
      controlOutlineWidth: 0,
      colorLinkActive: '#0958d9',
      colorPrimaryHover: '#808080',
    },
    InputNumber: {
      colorError: '#F3BFC3',
      colorErrorBorderHover: '#F3BFC3',
      colorErrorOutline: '',
      controlOutlineWidth: 0,
      colorText: '#414141',
      colorPrimaryHover: '#808080',
      colorBorder: '#808080',
      colorTextDisabled: 'rgba(35, 47, 106, 0.5)',
      colorTextPlaceholder: 'rgba(35, 47, 106, 0.5)',
      colorBgContainerDisabled: '#ffffff',
    },
    Radio: {
      lineWidth: 1,
      colorPrimary: '',
      colorBgContainerDisabled: '#ffffff',
      colorTextDisabled: 'rgba(0, 0, 0, 0.25)',
      fontSize: 16,
      colorWhite: '#232F6A',
      controlOutlineWidth: 2,
      colorBorder: '#232F6A',
      colorPrimaryActive: '#ffffff',
      lineWidthBold: 2,
      colorText: '#232F6A',
      controlOutline: 'rgba(1, 9, 28, 0.37)',
      padding: 16,
      colorPrimaryBorder: '',
      colorBgContainer: '',
      colorPrimaryHover: '#ffffff',
      controlHeight: 24,
    },
    Button: {
      colorPrimaryHover: '#001479',
      colorPrimaryActive: 'rgba(0, 20, 121, 0.8)',
      controlOutlineWidth: 0,
      controlHeight: 40,
      controlHeightLG: 60,
      colorBgContainerDisabled: '#BFBFBF',
      colorTextDisabled: '#ffffff',
      fontSize: 20,
      lineWidthBold: 2,
      colorBorder: '#414141',
      lineWidth: 2,
    },
    Tabs: {
      controlHeight: 64,
      colorBgContainer: '#F2F2F2',
      colorPrimary: '#414141',
      colorPrimaryActive: '#131a45',
      colorTextHeading: 'rgba(0, 0, 0, 0.88)',
      fontSize: 20,
      lineHeight: 1.3,
      padding: 16,
    },
    TreeSelect: {
      fontSize: 15,
      fontSizeLG: 20,
      colorText: '#414141',
      colorTextDisabled: 'rgba(35, 47, 106, 0.5)',
      colorBorder: '#808080',
    },
    Select: {
      borderRadius: 4,
      colorBorder: '#808080',
      colorBgContainer: '#ffffff',
      colorPrimaryHover: '#808080',
      colorPrimaryActive: '#808080',
      colorPrimary: '#808080',
      colorError: '#F3BFC3',
      controlOutline: '',
      controlOutlineWidth: 0,
      colorTextDisabled: 'rgba(35, 47, 106, 0.5)',
      colorTextDescription: 'rgba(0, 0, 0, 0.45)',
      colorText: '#414141',
      colorTextPlaceholder: 'rgba(35, 47, 106, 0.5)',
      colorFillAlter: '#ffffff',
      colorTextQuaternary: 'rgba(0, 0, 0, 0.25)',
      colorTextTertiary: 'rgba(0, 0, 0, 0.45)',
      colorBgContainerDisabled: '#ffffff',
    },
    Schema: {
      colorBg: '#f0f0f0',
      colorDefaultProperty: '#F2F2F2',
      colorAddProperty: '#ffffff',
      colorPropertyText: '#414141',
      colorPropertyType: '#808080',
      colorGridThickness: '#DBDBDB',
      colorGridLine: '#C3C3C3',
      colorPerspectiveTextClose: '#999999',
      colorPerspectiveIconClose: '#C6C6C6',
    },
  },
  token: {
    colorError: '#cf000f',
    colorErrorBorder: '#F3BFC3',
    colorSuccess: '#5aca75',
    colorPrimary: '#232f6a',
    borderRadius: 4,
    fontFamily: 'Rajdhani',
    fontSize: 20,
    fontSizeLG: 20,
    fontSizeSM: 15,
    lineHeight: 1.3,
    lineHeightHeading1: 1.3,
    fontSizeHeading1: 32,
    fontSizeHeading2: 30,
    fontSizeHeading3: 24,
    lineHeightHeading3: 1.3,
  },
};
