export const inSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="19" height="10" viewBox="0 0 19 10" fill="none">
<path d="M12.5715 5.86591C8.66297 5.86554 4.80196 5.86659 0.940946 5.86344C0.245289 5.86287 -0.0335476 5.56538 0.00318751 4.89674C0.0278186 4.44842 0.256306 4.20441 0.648986 4.14051C0.804023 4.11529 0.964854 4.13061 1.12305 4.1306C5.85437 4.13051 10.5857 4.13081 15.317 4.13105C15.4889 4.13106 15.6609 4.13105 15.8655 4.13105C15.8431 3.87639 15.6811 3.77513 15.5686 3.64989C14.9485 2.95928 14.3111 2.28676 13.6997 1.58712C13.2151 1.03251 13.3339 0.319803 13.9244 0.0646639C14.265 -0.0824896 14.5559 0.0336694 14.7928 0.29018C16.152 1.76191 17.501 3.24478 18.8588 4.71806C19.045 4.92014 19.0487 5.07333 18.8598 5.27815C17.5124 6.73835 16.1765 8.21119 14.8261 9.66798C14.4291 10.0962 13.9913 10.1012 13.6463 9.73491C13.3018 9.36928 13.3074 8.84446 13.6968 8.40182C14.2666 7.75422 14.8574 7.12869 15.4374 6.49177C15.5682 6.34814 15.6914 6.19632 15.8181 6.04826C15.8089 5.98748 15.7997 5.92669 15.7905 5.8659C14.7333 5.8659 13.6761 5.8659 12.5715 5.86591Z" fill="#F5B452"/>
</svg>`;

export const outSvg = `<svg fill="none" viewBox="0 0 19 9" height="9" width="19" xmlns="http://www.w3.org/2000/svg">
<path fill="#F27281" d="M9.17544 3.71981C12.1051 3.71977 14.9871 3.71957 17.8692 3.72018C18.0275 3.72021 18.188 3.71126 18.3434 3.73367C18.7504 3.79238 19.0066 4.10562 18.9999 4.51667C18.993 4.93805 18.7545 5.17773 18.3545 5.26162C18.1872 5.29672 18.0076 5.27904 17.8336 5.27908C13.2096 5.28004 8.58558 5.28018 3.96158 5.28046C3.68047 5.28048 3.39936 5.28046 2.96385 5.28046C3.73284 6.02326 4.39642 6.66149 5.05621 7.30346C5.22401 7.46673 5.41114 7.61726 5.50915 7.83742C5.67403 8.2078 5.6164 8.54619 5.30201 8.80681C5.0016 9.05582 4.66589 9.06247 4.34008 8.83627C4.23732 8.76493 4.14251 8.68039 4.0532 8.59291C2.76442 7.33059 1.48104 6.06293 0.185978 4.80686C-0.0388433 4.58881 -0.0783251 4.44388 0.170323 4.20364C1.49038 2.9282 2.79334 1.63573 4.10359 0.350506C4.34763 0.111123 4.62055 -0.070457 4.99072 0.0267181C5.61413 0.190377 5.80786 0.904971 5.33815 1.40274C4.8221 1.94961 4.27581 2.46957 3.73109 2.98955C3.51693 3.19398 3.27133 3.3667 3.04005 3.55386C3.06981 3.60918 3.09957 3.66451 3.12934 3.71983C5.12886 3.71983 7.12838 3.71983 9.17544 3.71981Z"/>
</svg>`;

export const allSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <circle cx="12" cy="12" r="4" fill="#232F6A"/>
  <circle cx="21" cy="12" r="3" fill="#232F6A" fill-opacity="0.5"/>
  <circle cx="3" cy="12" r="3" fill="#232F6A" fill-opacity="0.5"/>
  <circle cx="12" cy="21" r="3" fill="#232F6A" fill-opacity="0.5"/>
  <circle cx="12" cy="3" r="3" fill="#232F6A" fill-opacity="0.5"/>
  <circle cx="19" cy="5" r="2" fill="#232F6A" fill-opacity="0.5"/>
  <circle cx="5" cy="5" r="2" fill="#232F6A" fill-opacity="0.5"/>
  <circle cx="5" cy="19" r="2" fill="#232F6A" fill-opacity="0.5"/>
  <circle cx="19" cy="19" r="2" fill="#232F6A" fill-opacity="0.5"/>
</svg>`;
