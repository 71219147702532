import { COLORS } from 'helpers/constants';

export const ConnectionInverseIcon = ({
  firstColor = COLORS.PRIMARY.GRAY,
  secondColor = COLORS.PRIMARY.GRAY,
}: {
  firstColor?: string;
  secondColor?: string;
}) => {
  return (
    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id={firstColor}>
          <stop offset="0%" stopColor={firstColor} />
          <stop offset="100%" stopColor={secondColor} />
        </linearGradient>
      </defs>
      <path
        d="M12.5715 6.36591C8.66297 6.36554 4.80196 6.36659 0.940946 6.36344C0.245289 6.36287 -0.0335476 6.06538 0.00318751 5.39674C0.0278186 4.94842 0.256306 4.70441 0.648986 4.64051C0.804023 4.61529 0.964854 4.63061 1.12305 4.6306C5.85437 4.63051 10.5857 4.63081 15.317 4.63105C15.4889 4.63106 15.6609 4.63105 15.8655 4.63105C15.8431 4.37639 15.6811 4.27513 15.5686 4.14989C14.9485 3.45928 14.3111 2.78676 13.6997 2.08712C13.2151 1.53251 13.3339 0.819803 13.9244 0.564664C14.265 0.41751 14.5559 0.533669 14.7928 0.79018C16.152 2.26191 17.501 3.74478 18.8588 5.21806C19.045 5.42014 19.0487 5.57333 18.8598 5.77815C17.5124 7.23835 16.1765 8.71119 14.8261 10.168C14.4291 10.5962 13.9913 10.6012 13.6463 10.2349C13.3018 9.86928 13.3074 9.34446 13.6968 8.90182C14.2666 8.25422 14.8574 7.62869 15.4374 6.99177C15.5682 6.84814 15.6914 6.69632 15.8181 6.54826C15.8089 6.48748 15.7997 6.42669 15.7905 6.3659C14.7333 6.3659 13.6761 6.3659 12.5715 6.36591Z"
        fill={`url(#${firstColor})`}
      />
      <path
        d="M9.17544 14.2198C12.1051 14.2198 14.9871 14.2196 17.8692 14.2202C18.0275 14.2202 18.188 14.2113 18.3434 14.2337C18.7504 14.2924 19.0066 14.6056 18.9999 15.0167C18.993 15.4381 18.7545 15.6777 18.3545 15.7616C18.1872 15.7967 18.0076 15.779 17.8336 15.7791C13.2096 15.78 8.58558 15.7802 3.96158 15.7805C3.68047 15.7805 3.39936 15.7805 2.96385 15.7805C3.73284 16.5233 4.39642 17.1615 5.05621 17.8035C5.22401 17.9667 5.41114 18.1173 5.50915 18.3374C5.67403 18.7078 5.6164 19.0462 5.30201 19.3068C5.0016 19.5558 4.66589 19.5625 4.34008 19.3363C4.23732 19.2649 4.14251 19.1804 4.0532 19.0929C2.76442 17.8306 1.48104 16.5629 0.185978 15.3069C-0.0388433 15.0888 -0.0783251 14.9439 0.170323 14.7036C1.49038 13.4282 2.79334 12.1357 4.10359 10.8505C4.34763 10.6111 4.62055 10.4295 4.99072 10.5267C5.61413 10.6904 5.80786 11.405 5.33815 11.9027C4.8221 12.4496 4.27581 12.9696 3.73109 13.4895C3.51693 13.694 3.27133 13.8667 3.04005 14.0539C3.06981 14.1092 3.09957 14.1645 3.12934 14.2198C5.12886 14.2198 7.12838 14.2198 9.17544 14.2198Z"
        fill={`url(#${firstColor})`}
      />
    </svg>
  );
};
