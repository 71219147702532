import styled from 'styled-components';
import { Card as AntCard } from 'antd';
import { COLORS } from 'helpers/constants';

export const Card = styled(AntCard)`
  & {
    max-width: 378px;
    width: 100%;
    height: 332px;
    border: 1px solid ${COLORS.MAIN_GRAY};
    background-color: transparent;
    cursor: pointer;
    box-shadow: 9px 9px 6px ${COLORS.MAIN_GRAY_SILVER} !important;
  }

  &:hover {
    opacity: 0.5;
    transition: opacity 0.5s;
  }
`;

export const CardFooter = styled.div`
  & {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 16px;
  }

  & div {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;

export const AddCard = styled.div`
  & {
    max-width: 378px;
    width: 100%;
    height: 332px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: 3px dotted ${COLORS.MAIN_GRAY_SILVER};
    cursor: pointer;
  }
`;
