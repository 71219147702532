import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useGetUsersMe } from 'api/user/use-get-users-me';
import { useAuth } from 'context/auth-context';
import { PATHS } from 'helpers/constants';

export const OAuthAzure: React.FC = () => {
  const { login } = useAuth();
  const navigate = useNavigate();

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const accessToken = params.get('access_token') as string;
  const refreshToken = params.get('refresh_token') as string;

  const [userData, setUserData] = useState<{
    user?: {
      avatar?: string;
      id?: number;
      username?: string;
      first_name?: string;
      last_name?: string;
      bio?: string;
      email?: string;
      role?: string;
    };
    access_token?: string;
    refresh_token?: string;
  }>({});

  const { data } = useGetUsersMe({
    enabled: true,
    onSuccess: () => {
      setUserData({
        user: {
          avatar: data?.avatar,
          id: data?.id as number | undefined,
          username: data?.username || undefined,
          first_name: data?.first_name,
          last_name: data?.last_name,
          bio: '',
          email: data?.email,
          role: data?.role,
        },
        access_token: accessToken,
        refresh_token: refreshToken,
      });
      
    },
  });


  useEffect(() => {
    const handleLogin = async () => {
      if (userData?.user?.id && userData.access_token && userData.refresh_token) {
        try {
          await login({
            user: {
              avatar: userData.user.avatar,
              id: String(userData.user.id),
              username: userData.user.username,
              last_name: userData.user.last_name ?? '',
              email: userData.user.email ?? '',
              role: userData.user.role ?? '',
              bio: userData.user.bio,
              created_at: '',
              first_name: '',
              updated_at: '',
            },
            access_token: accessToken,
            refresh_token: refreshToken,
          });
          <Navigate to={PATHS.PROJECTS} replace/>
        } catch (error) {
        }
      }
    };
  
    handleLogin();
  }, [accessToken, login, navigate, refreshToken, userData]);
  
  

  return null;
};
