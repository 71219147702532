import { StyledBadge, TypeItem, StyledProjectName } from './styles';

export const renderNodes = (
  project_id: string,
  id: string,
  title: string,
  color: string,
  name: string,
  privacy: string
) => {
  return {
    key: `node-type${project_id}${id}`,
    id,
    mode: 'node',
    project_id: project_id,
    value: id,
    node_id: id,
    privacy,
    label: (
      <TypeItem>
        <StyledBadge color={color} text={name} />
        <StyledProjectName>Project: {title}</StyledProjectName>
      </TypeItem>
    ),
  };
};
