import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useGetUserGlobalSearch } from 'api/user/use-get-user-global-search';
import { AutoComplete, StyledSearchTitle } from './options/styles';
import { renderProjects } from './options/projects';
import { renderTypes } from './options/node-type';
import { renderNodes } from './options/nodes';
import { PATHS } from 'helpers/constants';
import { GET_SEARCH_GLOBAL_DATA, GET_SEARCH_GROUP_PROJECTS_DATA } from 'api/user/constants';
import { useGetSelectedSearchData } from 'api/visualisation/use-get-selected-search';
import { renderProperty } from './options/property-type';

enum ProjectPrivacy {
  PUBLIC = 'public',
  PRIVATE = 'private',
}

type SearchItem = {
  key: string;
  id: string;
  mode: string;
  value: string;
  privacy: string;
  label: React.ReactNode;
  node_id?: string;
  project_id?: string;
};

type ISearchProps = {
  typeSearch?: string;
  group?: string | null;
  onSetIsProjectId?: (value: string | undefined) => void;
};

export const Search = ({ typeSearch, onSetIsProjectId, group }: ISearchProps) => {
  const [search, setSearch] = useState<string>('');
  const [project, setProjectId] = useState<string | undefined>();
  const navigate = useNavigate();

  const url = group ? GET_SEARCH_GROUP_PROJECTS_DATA.replace(':id', group) : GET_SEARCH_GLOBAL_DATA;

  const { data } = useGetUserGlobalSearch(
    url,
    { enabled: search ? search.trim().length > 2 : false },
    search?.trim() ?? ''
  );

  const { mutate } = useGetSelectedSearchData(project ?? '', {
    onSuccess: (data) => {
      navigate(`${PATHS.PROJECT_VISUALISATION}`.replace(':id', project ?? ''), {
        state: {
          data: data.data,
        },
      });
    },
  });

  const projects = useMemo(
    () =>
      data?.projects?.map(({ id, title, color, icon, privacy }) => renderProjects(id, title, color, icon, privacy)) ??
      [],
    [data?.projects]
  );

  const nodeTypes = useMemo(
    () =>
      data?.types?.map(({ id, node_type_id, title, color, node_type_name, privacy }) =>
        renderTypes(id, node_type_id, title, color, node_type_name, privacy)
      ) ?? [],
    [data?.types]
  );

  const nodes = useMemo(
    () =>
      data?.nodes?.map(({ project_id, id, title, color, node_name, privacy }) =>
        renderNodes(project_id, id, title, color, node_name, privacy)
      ) ?? [],
    [data?.nodes]
  );

  const properties = useMemo(
    () =>
      data?.properties?.map(({ id, title, color, node_id, project_id, icon, privacy, user_id }) =>
        renderProperty(id, title, color, node_id, project_id, icon, privacy, user_id)
      ) ?? [],
    [data?.properties]
  );

  const renderTitle = (title: string) => <StyledSearchTitle>{title}</StyledSearchTitle>;

  const options = [];

  if (projects.length > 0) {
    options.push({
      label: renderTitle('Projects'),
      options: projects,
    });
  }

  if (nodeTypes.length > 0 && !typeSearch && !group) {
    options.push({
      label: renderTitle('Types'),
      options: nodeTypes,
    });
  }

  if ((nodes?.length > 0 && !typeSearch) || group) {
    options.push({
      label: renderTitle('Nodes'),
      options: nodes,
    });
  }

  if (properties?.length > 0 && group) {
    options.push({
      label: renderTitle('Properties'),
      options: properties,
    });
  }

  const onSelect = (id: string | unknown, item: { mode: string } | unknown) => {
    const { mode } = item as { mode: string };
    let list;
    switch (mode) {
      case 'nodeType':
        list = nodeTypes;
        break;
      case 'node':
        list = nodes;
        break;
      case 'propertyType':
        list = properties;
        break;
      default:
        list = projects;
        break;
    }

    const type = list?.find((data) => data.value === id) as SearchItem;

    const privacyPath = type?.privacy === ProjectPrivacy.PUBLIC ? `${PATHS.PUBLIC}` : '';

    switch (typeSearch) {
      case 'projects':
        const selectedItem = data?.projects?.find((el) => el?.id === id);
        if (selectedItem) {
          setSearch(selectedItem?.title);
          onSetIsProjectId?.(selectedItem?.id);
        }
        break;
      case 'group-projects':
        if (mode === 'node' || mode === 'propertyType') {
          setProjectId(type?.project_id);
          mutate({ id: type?.node_id ?? '', action: 'node' });
        } else {
          navigate(`${privacyPath}${PATHS.PROJECT_VISUALISATION.replace(':id', type?.id)}`);
        }
        break;
      default:
        navigate(`${privacyPath}${PATHS.PROJECTS}/${id}`);
        break;
    }
  };

  return (
    <AutoComplete
      popupClassName="certain-category-search-dropdown"
      popupMatchSelectWidth={456}
      dropdownMatchSelectWidth={400}
      style={{ width: 456 }}
      onSelect={onSelect}
      options={options}
      value={search}
    >
      <Input
        prefix={<SearchOutlined style={{ fontSize: '18px' }} />}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Search"
      />
    </AutoComplete>
  );
};
